import { render, staticRenderFns } from "./MagicGoalsIntroView.vue?vue&type=template&id=4e17ba98&"
import script from "./MagicGoalsIntroView.vue?vue&type=script&lang=js&"
export * from "./MagicGoalsIntroView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports