<template>
  <OnboardingStep
    :title="$t('onboarding.magicGoalsIntroView.title')"
    :next-text="$t('onboarding.magicGoalsIntroView.nextText')"
    v-bind="$props"
  >
    <I18nArray tag="p" path="onboarding.magicGoalsIntroView.paragraphs">
      <template #tip-alert>
        <VxAlert type="info">
          <b>{{ $t("onboarding.magicGoalsIntroView.tipAlert.prefix") }}</b>
          {{ $t("onboarding.magicGoalsIntroView.tipAlert.text") }}
        </VxAlert>
      </template>
    </I18nArray>
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import { VxAlert } from "@/core-ui";
import I18nArray from "@/components/I18nArray";

export default {
  name: "MagicGoalsIntroView",
  components: { I18nArray, VxAlert },
  extends: BaseStepView,
};
</script>
